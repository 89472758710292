import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React from 'react';
import ImageContainer from '../image-container';
import SectionContainerLayout from '../section-container-layout/section-container-layout';
import checkoutFormModel from './formModel/checkout-form-model';
import formInitialValues from './formModel/form-initial-values';
import validationSchema from './formModel/validation-schema';
import OccupationDetailsForm from './steppers/occupation-details-form';
import PersonalDetailsForm from './steppers/personal-details-form';
import SubscriptionsForm from './steppers/supscriptions-form';
const { formId, formField } = checkoutFormModel;

export default function StudentRegistrationFrom(props) {
  const navigate = useNavigate();
  async function submitForm(values, actions) {
    console.log(values);
    try {
      const REGData = {
        categoryCode: 'GUEWEBSIT',
        salutation: values.salutation.name,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        contact: values.mobile,
        countryCode: values.country.dialCode,
        country: values.country.name,
        nationality: values.nationality.name,
        jobTitle: values.jobTitle,
        jobRole: 'NA',
        companyName: values.company,
        industrySector: 'NA',
        events: ['GMIS-2024'],
        moreInfo: values.recieveMoreInfo,
        fromWebsite: true,
        tag: props.source,
        isInfosalon: true,
      };
      const response = await axios.post(process.env.GATSBY_EMS_API_URL + 'guests/addfromwebsite', REGData);

      if (response.status === 200) {
        navigate(`/registration/${props.page}/thankyou`);
      }
    } catch (err) {
      console.log(err);
    }
    actions.setSubmitting(false);
  }

  const handleFormikSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <SectionContainerLayout title='STUDENT REGISTRATION'>
      <Grid container spacing={3}>
        {props.imageName && (
          <Grid item xs={12} sm={6}>
            <Box>
              <ImageContainer filename={props.imageName} altText={props.imageAlt || '#GMIS2024'} />
            </Box>
          </Grid>
        )}
        <Grid item container xs={12} sm={props.imageName ? 6 : 12}>
          <Box width={1}>
            <React.Fragment>
              <Formik
                initialValues={formInitialValues}
                onSubmit={handleFormikSubmit}
                validationSchema={validationSchema}>
                {({ isSubmitting, values }) => {
                  return (
                    <Form id={formId} autoComplete='off'>
                      <PersonalDetailsForm allValues={values} formField={formField} />
                      <OccupationDetailsForm formField={formField} />
                      <SubscriptionsForm formField={formField} />

                      <Box mt={5}>
                        <Grid container justifyContent='flex-start'>
                          <Box order={{ sm: 1, md: 2 }} clone>
                            <Grid item xs={12} md={1} lg={1}>
                              <Box mt={1}>
                                <Button
                                  disabled={isSubmitting}
                                  fullWidth
                                  type='submit'
                                  variant='contained'
                                  color='primary'>
                                  Submit
                                </Button>
                                {isSubmitting && <CircularProgress size={24} />}
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </React.Fragment>
          </Box>
        </Grid>
      </Grid>
    </SectionContainerLayout>
  );
}
