import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import StudentRegistrationFrom from '../../../components/student-registration-form/registration-from';

export default function RegisterJAFZA(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2024 - Student' />
      <StudentRegistrationFrom
        source='Student'
        page='student'
        imageName='student-registration-page-img'
        imageAlt='student-expo'
      />
    </Layout>
  );
}
