import { Grid } from '@material-ui/core';
import React from 'react';
import CheckboxField from '../inputField/checkbox-field';

export default function CheckBoxInputForm(props) {
  const {
    formField: { recieveMoreInfo, conditions, gisManifesto },
  } = props;

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 0 }}>
        {/* <MultipleSelectField name={events.name} label={events.label} data={RegistrationJson.events} fullWidth /> */}
        {/* <CheckboxField name={accreditedMedia.name} label={accreditedMedia.label} /> */}
        {/* {isDataLoaded === true ? (
            <MultiSelection name={events.name} label={events.label} data={upComingEvents} />
            ) : null} */}
        <CheckboxField name={gisManifesto.name} checked label={gisManifesto.label} />
        <CheckboxField name={conditions.name} label={conditions.label} terms={true} />
        <CheckboxField name={recieveMoreInfo.name} label={recieveMoreInfo.label} />
        {/* <CheckboxField name={gisManifesto.name} label={gisManifesto.label} /> */}
      </Grid>
    </Grid>
  );
}
