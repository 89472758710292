export default {
  formId: 'studentRegistrationForm',
  formField: {
    salutation: {
      name: 'salutation',
      label: 'Salutation',
      requiredErrorMsg: 'salutation is required',
    },
    firstName: {
      name: 'firstName',
      label: 'First Name',
      requiredErrorMsg: 'First name is required',
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      requiredErrorMsg: 'Last name is required',
    },
    email: {
      name: 'email',
      label: 'Email',
      requiredErrorMsg: 'Email is required',
      invalidErrorMsg: 'Invalid email',
    },
    confirmedEmail: {
      name: 'confirmedEmail',
      label: 'Confirm Your Email',
      requiredErrorMsg: 'Email is required',
      invalidErrorMsg: 'Invalid email',
    },
    country: {
      name: 'country',
      label: 'Country of Residence',
      requiredErrorMsg: 'Country of residence is required',
    },
    nationality: {
      name: 'nationality',
      label: 'Nationality',
      requiredErrorMsg: 'Nationality is required',
    },
    jobTitle: {
      name: 'jobTitle',
      label: 'Job Title',
      requiredErrorMsg: 'Job title is required',
    },
    company: {
      name: 'company',
      label: 'Organisation',
      requiredErrorMsg: 'Organisation is required',
    },
    countryCode: {
      name: 'countryCode',
      label: 'Code',
      requiredErrorMsg: 'Country code is required',
    },
    mobile: {
      name: 'mobile',
      label: 'Mobile Number',
      requiredErrorMsg: 'Mobile number is required',
      invalidErrorMsg: 'Invalid phone number',
    },
    events: {
      name: 'events',
      label: 'I would like to register to attend #GMIS Connect China Webinar from November 27, 2023',
      requiredErrorMsg: 'Selecting Events is required',
    },
    gisManifesto: {
      name: 'gisManifesto',
      label: 'I agree to sign up for the manifesto for Global Industrial Safety',
    },
    recieveMoreInfo: {
      name: 'recieveMoreInfo',
      label:
        'I agree to receive marketing communications, updates, and promotional materials via email, SMS, or phone.',
    },
    conditions: {
      name: 'conditions',
      label: 'I agree to the Terms and Conditions *',
      requiredErrorMsg: 'Agreement to the terms and conditions is required',
    },
  },
};
