import checkoutFormModel from './checkout-form-model';
const {
  formField: {
    salutation,
    firstName,
    lastName,
    email,
    confirmedEmail,
    country,
    nationality,
    countryCode,
    jobTitle,
    company,
    mobile,
    events,
    gisManifesto,
    recieveMoreInfo,
    conditions,
  },
} = checkoutFormModel;

export default {
  [salutation.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [confirmedEmail.name]: '',
  [country.name]: '',
  [nationality.name]: '',
  [countryCode.name]: '',
  [jobTitle.name]: 'Student',
  [company.name]: '',
  [mobile.name]: '',
  // [events.name]: [],
  [events.name]: false,
  [gisManifesto.name]: true,
  [recieveMoreInfo.name]: true,
  [conditions.name]: true,
};
