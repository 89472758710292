import { Grid } from '@material-ui/core';
import React from 'react';
import InputField from '../inputField/Input-field';

export default function PersonalDetailsForm(props) {
  const {
    formField: { jobTitle, company },
  } = props;
  return (
    <Grid container>
      <Grid container spacing={2} item xs={12} style={{ marginBottom: 0 }}>
        <Grid item xs={12} sm={4}>
          <InputField label={company.label} name={company.name} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField limitchar={41} label={jobTitle.label} name={jobTitle.name} readonly disabled />
        </Grid>
      </Grid>
    </Grid>
  );
}
