import * as Yup from 'yup';
import checkoutFormModel from './checkout-form-model';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const {
  formField: {
    salutation,
    firstName,
    lastName,
    email,
    confirmedEmail,
    country,
    nationality,
    countryCode,
    company,
    jobTitle,
    mobile,
    recieveMoreInfo,
    conditions,
  },
} = checkoutFormModel;

export default Yup.object().shape({
  [salutation.name]: Yup.object().required(`${salutation.requiredErrorMsg}`),
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  [email.name]: Yup.string().required(`${email.requiredErrorMsg}`).email(`${email.invalidErrorMsg}`),
  [confirmedEmail.name]: Yup.string()
    .email(`${confirmedEmail.invalidErrorMsg}`)
    .required(`${confirmedEmail.requiredErrorMsg}`)
    .oneOf([Yup.ref('email'), null], 'Email must match'),
  [country.name]: Yup.object().required(`${country.requiredErrorMsg}`),
  [nationality.name]: Yup.object().required(`${nationality.requiredErrorMsg}`),
  // [countryCode.name]: Yup.string().required(`${countryCode.requiredErrorMsg}`),
  [mobile.name]: Yup.string().required(`${mobile.requiredErrorMsg}`).matches(phoneRegExp, `${mobile.invalidErrorMsg}`),

  [jobTitle.name]: Yup.string().required(`${jobTitle.requiredErrorMsg}`), // o
  [company.name]: Yup.string().required(`${company.requiredErrorMsg}`), // o
  // [jobRoleSpecify.name]: Yup.string() // s
  //   // .shape({ code: '10', name: 'Other' }),
  //   .test(jobRole.name, 'llllllllll', function () {
  //     const tttt = this.parent.jobRole !== { code: '10', name: 'Other' };

  //     return tttt;
  //   }),
  // .required(`${jobRoleSpecify.requiredErrorMsg}`),
  // .required(),
  // .ensure()
  // .when(jobRole.name, {
  //   is: { code: '0', name: 'Other' },
  //   then: Yup.string().required(),
  // }),

  // [jobRoleSpecify.name]: Yup.string().required(`${jobRoleSpecify.requiredErrorMsg}`),

  // [industrySectorSpecify.name]: Yup.string().required(`${industrySectorSpecify.requiredErrorMsg}`),
  // [industrySectorSpecify.name]: Yup.object()
  //   // .ensure()
  //   .when(industrySector.name, {
  //     is: { code: '42', name: 'Other' },
  //     then: Yup.string().isValidSync(),
  //   }),

  // [events.name]: Yup.array().of(Yup.string()).min(1, 'Event is required'),
  // [events.name]: Yup.boolean().oneOf([true], `${events.requiredErrorMsg}`),
  // [accreditedMedia.name]: Yup.boolean(),
  [recieveMoreInfo.name]: Yup.boolean(),
  [conditions.name]: Yup.boolean().oneOf([true], `${conditions.requiredErrorMsg}`),
});
