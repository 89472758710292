import { Grid } from '@material-ui/core';
import React from 'react';
import RegistrationJson from '../../../data/registration.json';
import InputField from '../inputField/Input-field';
import SearchSelectField from '../inputField/search-select-field';
import SelectField from '../inputField/select-field';

export default function PersonalDetailsForm(props) {
  const {
    formField: { salutation, firstName, lastName, email, confirmedEmail, country, nationality, countryCode, mobile },
    allValues: allValues,
  } = props;
  return (
    <Grid container justifyContent='space-between'>
      <Grid item xs={12} container spacing={2} style={{ marginBottom: 0 }}>
        <Grid item xs={12} sm={2}>
          <SelectField name={salutation.name} label={salutation.label} data={RegistrationJson.salutation} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField label={firstName.label} name={firstName.name} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField label={lastName.label} name={lastName.name} />
        </Grid>
      </Grid>

      <Grid container spacing={2} item xs={12} style={{ marginBottom: 0 }}>
        <Grid item xs={12} sm={6}>
          <InputField label={email.label} name={email.name} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField label={confirmedEmail.label} name={confirmedEmail.name} />
        </Grid>
      </Grid>

      <Grid container spacing={2} item xs={12} style={{ marginBottom: 0 }}>
        <Grid item xs={12} sm={4}>
          <SearchSelectField name={country.name} label={country.label} data={RegistrationJson.country} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SearchSelectField name={nationality.name} label={nationality.label} data={RegistrationJson.country} />
        </Grid>
        <Grid container spacing={2} item xs={12} sm={4}>
          <Grid item xs={4}>
            <InputField
              value={allValues.country && allValues.country.dialCode ? allValues.country.dialCode : ''}
              disabled
              label={countryCode.label}
              name={countryCode.name}
            />
          </Grid>
          <Grid item xs={8}>
            <InputField label={mobile.label} name={mobile.name} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
